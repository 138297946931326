<template>
  <div>
    <div class="wrapper">
      <button
        class="play-pause-icon"
        :class="[
          { 'is-small': isSmall },
          `${iconType}`,
          { active: isActive },
          `${animation}`,
        ]"
      >
        <div class="icon-wrapper" v-show="showPlayButton" @click="click">
          <PlayOutline
            v-if="iconType === 'outline'"
            :color="color"
            :secondary-color="secondaryColor"
            :opacity="opacity"
          />
          <PlayRound
            v-else-if="iconType === 'round'"
            :color="color"
            :secondary-color="secondaryColor"
            :opacity="opacity"
          />
          <PlaySquare
            v-else-if="iconType === 'square'"
            :color="color"
            :secondary-color="secondaryColor"
            :opacity="opacity"
          />
          <PlayMinimal v-else :color="color" />
        </div>
        <div class="icon-wrapper" v-show="!showPlayButton" @click="click">
          <PauseOutline
            v-if="iconType === 'outline'"
            :color="color"
            :secondary-color="secondaryColor"
            :opacity="opacity"
          />
          <PauseRound
            v-else-if="iconType === 'round'"
            :color="color"
            :secondary-color="secondaryColor"
            :opacity="opacity"
          />
          <PauseSquare
            v-else-if="iconType === 'square'"
            :color="color"
            :secondary-color="secondaryColor"
            :opacity="opacity"
          />
          <PauseMinimal v-else :color="color" />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PlayPause",
  components: {
    PlayMinimal: () => import("@/assets/play-pause-icons/play-minimal.vue"),
    PauseMinimal: () => import("@/assets/play-pause-icons/pause-minimal.vue"),
    PlayOutline: () => import("@/assets/play-pause-icons/play-outline.vue"),
    PauseOutline: () => import("@/assets/play-pause-icons/pause-outline.vue"),
    PlayRound: () => import("@/assets/play-pause-icons/play-round.vue"),
    PauseRound: () => import("@/assets/play-pause-icons/pause-round.vue"),
    PlaySquare: () => import("@/assets/play-pause-icons/play-square.vue"),
    PauseSquare: () => import("@/assets/play-pause-icons/pause-square.vue"),
  },
  props: {
    showPlayButton: {
      type: Boolean,
      default: true,
    },
    showPauseButton: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },

    hasNotClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clickedRecently: false,
    };
  },
  computed: {
    ...mapGetters("playerCampaign", ["format"]),

    iconSettings() {
      return this.format?.iconSettings;
    },
    isActive() {
      return this.hasNotClicked || this.clickedRecently;
    },
    iconType() {
      return this.iconSettings?.iconType;
    },
    color() {
      return this.iconSettings?.color || "white";
    },
    secondaryColor() {
      return this.iconSettings?.secondaryColor || "#585858";
    },
    opacity() {
      return this.iconSettings?.opacity || 1;
    },
    animation() {
      return this.iconSettings?.animation || "none";
    },
  },
  methods: {
    click() {
      this.clickedRecently = true;
      setTimeout(() => {
        this.clickedRecently = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: transparent;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $index-mid-position;
}
.play-pause-icon {
  padding: 0;
  background: transparent;
  &.active.wave {
    -webkit-animation: ripple 1.2s infinite;
    animation: ripple 1.2s infinite;
  }
  &.active.scale-down {
    -webkit-animation: scale-down-center 1.6s infinite;
    animation: scale-down-center 1.6s infinite;
  }
  svg {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }
  &.minimal {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.round,
  &.outline {
    border-radius: 50%;
  }
}

.icon-wrapper {
  display: flex;
  height: 40px;
  width: 40px;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0px transparent, 0 0 0 0px #303030, 0 0 0 0px transparent,
      0 0 0 0px #303030, 0 0 0 0px transparent, 0 0 0 0px #303030,
      0 0 0 0px transparent, 0 0 0 0px #303030;
  }
  100% {
    box-shadow: 0 0 0 2px transparent, 0 0 0 4px rgba(255, 255, 255, 0),
      0 0 0 8px transparent, 0 0 0 10px rgba(255, 255, 255, 0);
  }
}
@keyframes scale-down-center {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.play-pause-icon.is-small.none,
.play-pause-icon.is-small.wave {
  transform: scale(0.5);
}
.play-pause-icon.is-small.scale-down .icon-wrapper {
  transform: scale(0.5);
}
</style>